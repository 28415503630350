/**
 * @module Quick Rules
 * @description
 */
import Panel from '@molecules/Panel';
import Section from '@molecules/Section';
import OrderedList from '@molecules/Lists/OrderedList';
import PlayDemo from '@molecules/PlayDemo';
import styles from './index.module.css';

/**
 * @function QuickRules
 * @description
 */
const QuickRules = () => (
  <Section>
    <Panel title="Quick Rules">
      <div className={ styles.content }>
        <OrderedList>
          <li>Make bets to receive a blackjack and autojack hand, by making a blackjack and autojack ante bet. Optionally make Point N Suit and/or Break Under 17 bet.</li>
          <li>Receive your starting bj and aj hands; dealer receives a up-card and down-card.</li>
          <li>Dealer resolves Point N Suit bet.</li>
          <li>Dealer addresses Insurance and Blackjacks; if needed.</li>
          <li>If dealer didn't have blackjack, choose to play or surrender your aj hand. If you choose play, make an autojack play bet equal to your autojack ante bet. If you choose surrender, you forfeit half your autojack ante bet and receive half of it back.</li>
          <li>Complete action on your bj hand as in regular blackjack.</li>
          <li>Receive automatic hits on your aj hand until the hand totals 21 or more; if you previously chose to play your aj hand or have a Break Under 17 bet. If total is 21, that's total for that hand. If total exceeds 21, total before exceeding 21 is total for that hand.</li>
          <li>Dealer acts on their hand. If their total is 17 to 21, that total is used against your bj and aj hands. If their total exceeds 21, a bust is played against your bj hand and the total before exceeding 21 is played against your aj hand.</li>
          <li>Dealer resolves remaining bets; see Bet Resolving Table.</li>
        </OrderedList>
      </div>

      <PlayDemo />
    </Panel>
  </Section>
);

export default QuickRules;
