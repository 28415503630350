/**
 * @module Form
 * @description
 */
import Field from './components/Field';
import FieldGroup from './components/FieldGroup';
import Button from '@atoms/Button';
import styles from './index.module.css';

/**
 * @function Form
 * @description
 */
const Form = () => (
  <form
    className={ styles.form }
    name="contact-form"
    data-netlify="true"
    action="/confirmation"
    method="POST"
  >
    <FieldGroup>
      <Field>
        <label
          htmlFor="first-name"
          className={ styles.label }
        >
          First Name
        </label>
        <input
          type="text"
          name="first-name"
          id="first-name"
          className={ styles.input }
        />
      </Field>

      <Field>
        <label
          htmlFor="last-name"
          className={ styles.label }
        >
          Last Name
        </label>
        <input
          type="text"
          name="last-name"
          id="last-name"
          className={ styles.input }
        />
      </Field>
    </FieldGroup>

    <FieldGroup>
      <Field className={ styles.field }>
        <label
          htmlFor="email"
          className={ styles.label }
        >
          Email Address
        </label>
        <input
          type="email"
          name="email"
          id="email"
          className={ styles.input }
        />
      </Field>

      <Field>
        <label
          htmlFor="phone"
          className={ styles.label }
        >
          Phone Number
        </label>
        <input
          type="text"
          name="phone"
          id="phone"
          className={ styles.input }
        />
      </Field>
    </FieldGroup>

    <FieldGroup>
      <Field>
        <label
          htmlFor="organization-company"
          className={ styles.label }
        >
          Organization / Company
        </label>
        <input
          type="phone"
          name="organization-company"
          id="organization-company"
          className={ styles.input }
        />
      </Field>
    </FieldGroup>

    <FieldGroup>
      <Field>
        <label
          htmlFor="message"
          className={ styles.label }
        >
          Message
        </label>
        <textarea
          name="message"
          id="message"
          className={ styles.textarea }
          rows="6"
          cols="50"
        />
      </Field>
    </FieldGroup>

    <input type="hidden" name="form-name" value="contact-form" />

    <div className={ styles.submit }>
      <Button type="submit" className={ styles.submitBtn }>Submit</Button>
    </div>
  </form>
);

export default Form;
