/**
 * @module Home
 * @description Home Page
 */
import Head from 'next/head';
import Template from '@templates/Landing';
import Welcome from '@organisms/Welcome';
import GameDescription from '@organisms/GameDescription';
import QuickRules from '@organisms/QuickRules';
import HowToPlay from '@organisms/HowToPlay';
import BetResolvingTable from '@organisms/BetResolvingTable';
import TableGamesGurus from '@organisms/TableGamesGurus';
import CasinoGamers from '@organisms/CasinoGamers';
import ContactFormModal from '@organisms/ContactForm';

/**
 * @function Page
 * @description Page
 */
const Page = () => (
  <>
    <Head>
      <title>Home | Autojack21</title>
    </Head>

    <Template
      title="Home"
      main={
        <>
          <Welcome />
          <GameDescription />
          <QuickRules />
          <HowToPlay />
          <BetResolvingTable />
          <TableGamesGurus />
          <CasinoGamers />
        </>
      }
      modal={ <ContactFormModal /> }
    />
  </>
);
export default Page;
