/**
 * @module FieldGroup
 * @description
 */
import styles from '../index.module.css';

/**
 * @function FieldGroup
 * @description
 */
const FieldGroup = ( { children } ) => (
  <div className={ styles.fieldGroup }>
    { children }
  </div>
);

export default FieldGroup;
