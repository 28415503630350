/**
 * @module Ordered List
 * @description
 */
import useConditionalClasses from '@hooks/useConditionalClasses';
import styles from './OrderedList.module.css';

/**
 * @function OrderedList
 * @description
 */
const OrderedList = ( {
  children,
  className = '',
  withIcons
} ) => {
  const classes = {
    withIcons
  };
  const modifierClasses = useConditionalClasses( styles, classes );

  return (
    <ol
      className={ `
        ${ styles.ol }
        ${ modifierClasses }
        ${ className }
      ` }
    >
      { children }
    </ol>
  );
};

export default OrderedList;
