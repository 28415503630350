/**
 * @module Strong
 * @description
 */
import styles from './Strong.module.css';

/**
 * @function Strong
 * @description
 */
const Strong = ( {
  children,
  className = ''
} ) => (
  <strong
    className={ `
      ${ styles.strong }
      ${ className }
    ` }
  >
    { children }
  </strong>
);

export default Strong;
