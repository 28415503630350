/**
 * @module Modal
 * @description
 */
import { useEffect, useState } from 'react';
import MicroModal from 'micromodal';
import Heading from '@atoms/Typography/Heading';
import styles from './index.module.css';

/**
 * @function Modal
 * @description
 */
const Modal = ( {
  children,
  id,
  title
} ) => {
  const [ isOpen, setOpen ] = useState( false );

  /**
 * @function useEffect
 * @description Kick off this modules functions
 */
  useEffect( () => {
    MicroModal.init( {
      onShow: () => setOpen( true ),
      onClose: () => setOpen( false ),
      disableScroll: true,
      disableFocus: true,
      awaitOpenAnimation: false,
      awaitCloseAnimation: false,
      debugMode: true
    } );

    MicroModal.show( `modal-${ id }` );
  }, [ id ] );

  return (
    <div
      className={ `${ styles.modal } ${ styles.micromodalSlide } ${ isOpen ? styles.isOpen : '' }` }
      id={ `modal-${ id }` }
      aria-hidden="true"
    >
      <div className={ styles.modalOverlay } tabIndex="-1" data-micromodal-close>
        <div className={ styles.modalContainer } role="dialog" aria-modal="true">
          <div className={ styles.modalHeader }>
            <button className={ styles.modalClose } aria-label="Close modal" data-micromodal-close>Close</button>
            <Heading level="2" content={ title } className={ styles.title } />
          </div>

          <div className={ styles.modalContent }>
            { children }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
