/**
 * @module Menu
 * @description
 */
import Navigation from '@atoms/Navigation';
import UnorderedList from '@molecules/Lists/UnorderedList';
import styles from './index.module.css';

/**
 * @function Menu
 * @description
 */
const Menu = ( {
  children,
  className = ''
} ) => (
  <Navigation className={ className }>
    <UnorderedList className={ styles.menu }>
      { children }
    </UnorderedList>
  </Navigation>
);

export default Menu;
