/**
 * @module Game Description
 * @description
 */
import Paragraph from '@atoms/Typography/Paragraph';
import Panel from '@molecules/Panel';
import Section from '@molecules/Section';
import Anchor from '@atoms/Typography/Anchor';
import Em from '@atoms/Typography/Em';
import styles from './index.module.css';

/**
 * @function GameDescription
 * @description
 */
const GameDescription = () => (
  <Section>
    <Panel title="Game Description">
      <div className={ styles.content }>
        <Paragraph><Em>AUTOJACK BLACKJACK&trade;</Em> is a variant of <Anchor href="https://en.wikipedia.org/wiki/Blackjack" title="Blackjack" target="_blank" />. It is played with one or more standard, 52-card decks of playing cards. All cards played in this game have the same point-values as they do in regular Blackjack.</Paragraph>

        <Paragraph>
          With this game, you receive a Blackjack hand. This hand is played the same as a Blackjack hand is played in regular Blackjack. This provides you with your favorite game play that you find in regular Blackjack; such as doubling-down and splitting.
          You also receive an Autojack hand. This hand plays similar to a Blackjack hand, but receives automatic hits and cannot bust should you choose to Play it and not Surrender it. If you like your Autojack hand and want to keep it in action, you must
          make an additional Play wager to do so. If you don't like this hand, you can Surrender it, and receive half of your wager you have on it back. Your starting Blackjack and Autojack hands can have Blackjack, if they start with an Ace and a 10-point
          card; this gives you more chances of getting a Blackjack. A winning Blackjack still pays 3 to 2 with our game. This game also offers you two optional sidebets for you to wager on; one with a top payout of 500 to 1.
        </Paragraph>
      </div>
    </Panel>
  </Section>
);

export default GameDescription;
