/**
 * @module Em
 * @description
 */
import styles from './Em.module.css';

/**
 * @function Dropcap
 * @description
 */
const Em = ( {
  children,
  className = ''
} ) => (
  <em
    className={ `
      ${ styles.em }
      ${ className }
    ` }>
    { children }
  </em>
);

export default Em;
