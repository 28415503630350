/**
 * @module How To Play
 * @description
 */
import Paragraph from '@atoms/Typography/Paragraph';
import Panel from '@molecules/Panel';
import Section from '@molecules/Section';
import styles from './index.module.css';

/**
 * @function HowToPlay
 * @description
 */
const HowToPlay = () => (
  <Section>
    <Panel title="How To Play">
      <div className={ styles.content }>
        <Paragraph>Make a required Blackjack bet and Autojack Ante bet before cards are dealt. Optionally, make a Point N Suit and/or Break Under 17 bet at this time.</Paragraph>

        <Paragraph>
          When you make an Autojack Ante bet, you receive an Autojack hand. Your Autojack hand is your  hand dealt closest to your Autojack Ante bet.
          There are only two actions you can take on your Autojack hand. The actions or either Play or Surrender. You must choose to Play, or to Surrender this hand against the dealer, before you act on your Blackjack hand.
          If you choose to Play this hand against the dealer, you must make an Autojack Play bet equal to your Autojack Ante bet. This hand and the dealer's hand will play against each other, as a Blackjack hand plays against a dealer
          in regular Blackjack; with a couple exceptions. The first exception, the dealer will automatically hit your Autojack hand, after you've completed your action on your Blackjack hand; if you made the Autojack Play bet. The dealer
          hits this hand until it totals 21 points or exceeds 21 points. If your Autojack hand totals 21 points, that is your final total for this hand. If your Autojack hand exceeds 21 points, the last card dealt that made your total
          exceed 21 points is removed from your total; the remaining total is now your final total for this hand. The second exception, the dealer can't bust when playing against your Autojack hand, or for the Break Under 17 bet.
          If the dealer's hand total exceeds 21 points, the last card dealt that made the total exceed 21 points is removed from their total; the remaining total is now their final total for their hand when playing against your Autojack hand,
          or for the Break Under 17 bet. If you choose to Surrender, you lose half your Autojack Ante bet, and the other half is returned to you; you will not play your Autojack hand against the dealer. If you choose to Surrender, and have
          a Break Under 17 bet wagered, your Autojack hand is automatically hit as described above; to allow for your Break Under 17 bet to play out. The dealer also plays their hand out if there is at least one Break Under 17 bet made.
        </Paragraph>

        <Paragraph>When you make a Blackjack bet, you receive and play a Blackjack hand against the dealer just as in regular Blackjack. Your Blackjack hand is your hand dealt closest to your Blackjack bet. All rules that apply in regular Blackjack apply to this bet. You act on your Blackjack hand, after you've acted on your Autojack hand.</Paragraph>

        <Paragraph><em className={ styles.italics }>Note: Insurance is offered, if the dealer's up-card is an Ace card. Insurance is offered after the Point N Suit bets are resolved. A winning Insurance wager pays 2 to 1. The maximum Insurance a player can take, is an amount equal to half of the sum of their Blackjack, Autojack Ante and Break Under 17 bets.</em></Paragraph>
      </div>
    </Panel>
  </Section>
);

export default HowToPlay;
