/**
 * @module CasinoGamers
 * @description
 */
import Paragraph from '@atoms/Typography/Paragraph';
import Panel from '@molecules/Panel';
import Section from '@molecules/Section';
import PlayDemo from '@molecules/PlayDemo';
import Em from '@atoms/Typography/Em';
import Strong from '@atoms/Typography/Strong';
import styles from './index.module.css';

/**
 * @function CasinoGamers
 * @description
 */
const CasinoGamers = () => (
  <Section className={ styles.section }>
    <Panel title="Casino Gamers">
      <Paragraph>
        Our fast-paced Blackjack game makes it impossible for you to bust on a hand you make a Play wager on. The dealer will even hit that hand when it totals 20; with no chance of you busting it. Other no-bust blackjack games limit you by not allowing you to take another hit once you have a hand total of 17.
        With <Em>AUTOJACK BLACKJACK&trade;</Em>, there are no additional dealer hand totals that cause a push; as is found in some other blackjack variants. If you would like to try this new and exciting game click on the <Strong>"Play Now"</Strong> button below.
      </Paragraph>

      <PlayDemo />
    </Panel>
  </Section>
);

export default CasinoGamers;
