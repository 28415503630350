/**
 * @module Menu Item
 * @description
 */
import styles from './MenuItem.module.css';

/**
 * @function MenuItem
 * @description
 */
const MenuItem = ( {
  children,
  className = ''
} ) => (
  <li
    className={ `
      ${ styles.li }
      ${ className }
    ` }
  >
    { children }
  </li>
);

export default MenuItem;
