/**
 * @module Lead
 * @description
 */
import Paragraph from './Paragraph';
import styles from './Lead.module.css';

/**
 * @function Lead
 * @description
 */
const Lead = ( {
  children,
  className = ''
} ) => (
  <Paragraph
    className={ `
      ${ styles.lead }
      ${ className }
    ` }
    >
      { children }
  </Paragraph>
);

export default Lead;
