/**
 * @module Welcome
 * @description
 */
import Container from '@atoms/Structures/Container';
import Lead from '@atoms/Typography/Lead';
import Heading from '@atoms/Typography/Heading';
import Section from '@molecules/Section';
import Em from '@atoms/Typography/Em';
import styles from './index.module.css';

/**
 * @function Welcome
 * @description
 */
const Welcome = () => (
  <Section className={ styles.section }>
    <Container>
      <Heading level="2" className={ styles.heading } appearH1 content={ <>Welcome to <em className={ styles.emLine }>Autojack21</em></> }/>
      <Lead className={ styles.lead }>The <Em className={ styles.em }>Autojack Blackjack&trade;</Em> resource page. Here you can learn about this new and exciting, fast-paced Blackjack variant, as well as play our online version.</Lead>
    </Container>
  </Section>
);

export default Welcome;
