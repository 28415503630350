/**
 * @module TableGamesGurus
 * @description
 */
import Panel from '@molecules/Panel';
import Paragraph from '@atoms/Typography/Paragraph';
import Section from '@molecules/Section';
import Em from '@atoms/Typography/Em';
import Button from '@atoms/Button';
import Menu from '@molecules/Menu';
import MenuItem from '@molecules/Menu/MenuItem';
import styles from './index.module.css';

/**
 * @function TableGamesGurus
 * @description
 */
const TableGamesGurus = () => (
  <Section className={ styles.section }>
    <Panel title="Table Games Gurus">
      <Paragraph>If you're a Table Games Guru, responsible for the Table Game selections on your Casino floor, we would love to present to you, as to why you should make <Em>Autojack Blackjack&trade;</Em> part of your lineup.</Paragraph>

      <Menu className={ styles.nav }>
        <MenuItem className={ styles.navItem }>
          <Button className={ styles.navButton } isLink href="/Autojack-Math.pdf" target="_blank">See The Math</Button>
          <Button className={ styles.navButton } data-micromodal-trigger="modal-contact-us">Table Games Gurus</Button>
        </MenuItem>
      </Menu>
    </Panel>
  </Section>
);

export default TableGamesGurus;
