/**
 * @module Bet Resolving Table
 * @description
 */
import {
  Table, Thead, Tbody, Tr, Th, Td
} from 'react-super-responsive-table';
import Panel from '@molecules/Panel';
import Section from '@molecules/Section';
import PlayDemo from '@molecules/PlayDemo';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import styles from './index.module.css';

/**
 * @function BetResolvingTable
 * @description
 */
const BetResolvingTable = () => (
  <Section>
    <Panel title="Bet Resolving Table">
      <Table className={ styles.table }>
        <Thead>
          <Tr>
            <Th>Bet Name</Th>
            <Th>Pushes</Th>
            <Th>Losses</Th>
            <Th>Wins</Th>
            <Th>Payout</Th>
          </Tr>
        </Thead>

        <Tbody>
          <Tr>
            <Td>Blackjack</Td>
            <Td>If your final Blackjack hand total has the same point total as the Dealer's.</Td>
            <Td>If your final Blackjack hand total has a lower point total than the Dealer's.</Td>
            <Td>If your final Blackjack hand total has a higher point total than the Dealer's.</Td>
            <Td>1:1 <br/>Blackjack - 3:2</Td>
          </Tr>

          <Tr>
            <Td>Autojack Ante</Td>
            <Td>If your final Autojack hand total has the same point total as the Dealer's.</Td>
            <Td>If your final Autojack hand total has a lower point total than the Dealer's.</Td>
            <Td>If your final Autojack hand total has a higher point total than the Dealer's.</Td>
            <Td>1:1 <br/>Blackjack - 3:2</Td>
          </Tr>

          <Tr>
            <Td>Autojack Play</Td>
            <Td>If the Dealer initially exceeds 21 points, or if your final Autojack hand total has the same point total as the Dealer's; when the Dealer doesn't initially exceed 21 points.</Td>
            <Td>If your final Autojack hand total has a lower point total than the Dealer's; when the Dealer doesn't initially exceed 21 points.</Td>
            <Td>If your final Autojack hand total has a higher point total than the Dealer's; when the Dealer doesn't initially exceed 21 points.</Td>
            <Td>1:1</Td>
          </Tr>

          <Tr>
            <Td>Point N Suit</Td>
            <Td>N/A</Td>
            <Td>If neither of your starting Blackjack or Autojack hands contain a card that matches the point and suit of the Dealer's up-card.</Td>
            <Td>If either of your starting Blackjack or Autojack hands contain a card that matches the point and suit of the Dealer's up-card.</Td>
            <Td>Match 4 - 500:1 <br/>Match 3 - 100:1 <br/>Match 2 - 15:1 <br/>Match 1 - 5:1</Td>
          </Tr>

          <Tr>
            <Td>Break Under 17</Td>
            <Td>N/A</Td>
            <Td>If the Dealer doesn't bust, or if your final Autojack hand total isn't under hard 17.</Td>
            <Td>If the Dealer busts, and your final Autojack hand is under hard 17.</Td>
            <Td>10:1</Td>
          </Tr>
        </Tbody>
      </Table>

      <PlayDemo />
    </Panel>
  </Section>
);

export default BetResolvingTable;
