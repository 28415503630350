/**
 * @module Navigation
 * @description
 */
import styles from './index.module.css';

/**
 * @function Navigation
 * @description
 */
const Navigation = ( {
  children,
  className = ''
} ) => (
  <nav
    role="navigation"
    className={ `
      ${ styles.navigation }
      ${ className }
    ` }
  >{ children }
  </nav>
);

export default Navigation;
