/**
 * @module ContactForm
 * @description
 */
import Modal from '@molecules/Modal';
import Form from './Form';

/**
 * @function ContactForm
 * @description
 */
const ContactForm = () => (
  <Modal id="contact-us" title="Contact Us">
    <Form/>
  </Modal>
);

export default ContactForm;
