/**
 * @module Field
 * @description
 */
import styles from '../index.module.css';

/**
 * @function Field
 * @description
 */
const Field = ( { children } ) => (
  <div className={ styles.field }>
    { children }
  </div>
);

export default Field;
