/**
 * @module PlayDemo
 * @description
 */
import Button from '@atoms/Button';
import Menu from '@molecules/Menu';
import MenuItem from '@molecules/Menu/MenuItem';
import styles from './index.module.css';

/**
 * @function CasinoGamers
 * @description
 */
const PlayDemo = () => {
  const handleClickGame = () => {
    window.open(
      '/game',
      'targetWindow',
      'toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=1140, height=760'
    );
  };

  return (
    <Menu className={ styles.nav }>
      <MenuItem className={ styles.navItem }>
        <Button className={ styles.navButton } onClick={ handleClickGame }>Play Now</Button>
      </MenuItem>
    </Menu>
  );
};

export default PlayDemo;
